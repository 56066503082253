<template>
  <div class="d-flex">
    <btn
      type="button"
      class="btn-link btn-link-primary"
      @click="toggle"
    >
      {{ text }}
    </btn>
  </div>
</template>

<script>
export default {
  data: () => ({
    clipped: false,
  }),

  computed: {
    text () {
      return this.clipped ? 'Read more' : 'Show less';
    },
  },

  mounted () {
    this.setClipped();
    this.init();
  },

  methods: {
    init () {
      const $description = document.querySelector('.js-dog-breed-description p');

      if ($description && $description.scrollHeight === $description.clientHeight) {
        this.$destroy();
        this.$el.parentNode.removeChild(this.$el);
      }
    },

    setClipped () {
      const $description = document.querySelector('.js-dog-breed-description p');

      if (!$description) {
        return;
      }

      this.clipped = $description.scrollHeight > $description.clientHeight;
    },

    toggle () {
      const $description = document.querySelector('.js-dog-breed-description p');

      if (!$description) {
        return;
      }

      if (this.clipped) {
        $description.setAttribute('style', '-webkit-line-clamp: 1000;');
      } else {
        $description.setAttribute('style', '');
      }

      this.setClipped();
    },
  },
};
</script>
