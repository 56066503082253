import { Tooltip } from 'bootstrap';

export default {
  init () {
    [...document.querySelectorAll('[data-bs-toggle="tooltip"]')].forEach(
      (domElement) => new Tooltip(domElement),
    );
  },

  destroy () {
    document.querySelectorAll('.tooltip').forEach((e) => e.remove());
  },
};
