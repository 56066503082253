const scroll = (top = 0) => {
  window.scrollTo({
    top,
    behavior: 'smooth',
  });
};

export default {
  top (event = null) {
    if (event) event.preventDefault();

    scroll();
  },

  to (id) {
    const $target = document.getElementById(id);

    if ($target) {
      const top = $target.getBoundingClientRect().top;

      scroll(top + window.scrollY - 64);
    }
  },
};
