<template>
  <div class="text-center mt-5">
    <h1
      class="mb-3"
    >
      {{ pageTitle }}
    </h1>
    <div v-if="!codeSent">
      <div class="position-relative">
        <div class="mb-4">
          A verification code will be sent to <br class="d-md-none"><strong>{{ getFormattedPhoneNumber }}</strong> via SMS.
          <br>
          <br class="d-md-none">If the phone number was entered incorrectly, you can change it
          <br class="d-none d-md-block">on the <a href="/profile" class="btn-link btn-link-primary">profile settings page</a>.
        </div>
        <btn
          type="button"
          class="btn btn-primary"
          @click="sendSMSVerificationCode"
        >
          Send code
        </btn>
        <div class="mt-4">
          <btn
            type="button"
            class="btn-link btn-link-primary"
            @click="codeSent = true"
          >
            I already have the code
          </btn>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row justify-content-center text-center">
        <div class="col-md-6 col-lg-5">
          <div class="position-relative">
            <div class="card py-4 px-3 mb-3">
              <div
                class="mb-2 mx-5"
              >
                Please enter the verification code we sent to your phone number <strong>{{ getFormattedPhoneNumber }}</strong>
              </div>
              <div
                class="d-flex flex-row justify-content-center mt-2"
              >
                <form
                  id="js-otc-form"
                  @submit.prevent="submitVerificationCode"
                >
                  <fieldset
                    class="otc-fieldset"
                  >
                    <!-- https://developer.apple.com/documentation/security/password_autofill/enabling_password_autofill_on_an_html_input_element -->
                    <input
                      id="otc-1"
                      v-model="otp.num1"
                      type="number"
                      pattern="[0-9]*"
                      inputtype="numeric"
                      required
                      autocomplete="one-time-code"
                      @input="handleInputPaste"
                      @focus="handleInputFocus"
                      @keyup="handleInputKeyUp"
                    >
                    <input
                      id="otc-2"
                      v-model="otp.num2"
                      type="number"
                      pattern="[0-9]*"
                      inputtype="numeric"
                      required
                      @input="handleInputPaste"
                      @focus="handleInputFocus"
                      @keyup="handleInputKeyUp"
                    >
                    <input
                      id="otc-3"
                      v-model="otp.num3"
                      type="number"
                      pattern="[0-9]*"
                      inputtype="numeric"
                      required
                      @input="handleInputPaste"
                      @focus="handleInputFocus"
                      @keyup="handleInputKeyUp"
                    >
                    <input
                      id="otc-4"
                      v-model="otp.num4"
                      type="number"
                      pattern="[0-9]*"
                      inputtype="numeric"
                      required
                      @input="handleInputPaste"
                      @focus="handleInputFocus"
                      @keyup="handleInputKeyUp"
                    >
                    <input
                      id="otc-5"
                      v-model="otp.num5"
                      type="number"
                      pattern="[0-9]*"
                      inputtype="numeric"
                      required
                      @input="handleInputPaste"
                      @focus="handleInputFocus"
                      @keyup="handleInputKeyUp"
                    >
                    <input
                      id="otc-6"
                      v-model="otp.num6"
                      type="number"
                      pattern="[0-9]*"
                      inputtype="numeric"
                      required
                      @input="handleInputPaste"
                      @focus="handleInputFocus"
                      @keyup="handleInputKeyUp"
                    >
                  </fieldset>
                  <div>
                    <btn
                      type="submit"
                      class="btn btn-primary mt-4 px-4"
                    >
                      Verify
                    </btn>
                  </div>
                </form>
              </div>
            </div>
            <div class="card-2">
              <div class="content d-flex justify-content-center align-items-center">
                <span>Didn't get the code?</span>
                <a
                  href="javascript:;"
                  class="text-decoration-none ms-3"
                  @click.prevent="sendSMSVerificationCode"
                >
                  Resend ({{ attemptsLeft }}/2)
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast as VueToast } from 'vue-toast-notification';

export default {
  props: {
    apiRouteSendSmsVerificationCode: {
      type: String,
      required: true,
    },
    apiRouteCheckSmsVerificationCode: {
      type: String,
      required: true,
    },
    phoneNumber: {
      type: String,
      required: true,
    },
    pageTitle: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    codeSent: false,
    attemptsLeft: 0,
    otp: {
      num1: '',
      num2: '',
      num3: '',
      num4: '',
      num5: '',
      num6: '',
    },
  }),

  computed: {
    getFormattedPhoneNumber () {
      return this.phoneNumber.replace(/\s/g, '');
    },
  },

  watch: {
    codeSent (newVal, oldVal) {
      if (newVal && !oldVal) {
        this.$nextTick(() => {
          this.registerInputKeyUpEvents();
        });
      }
    },
  },

  mounted () {
    if (this.$route.hash === '#scroll') {
      this.$scroll.to('js-breadcrumb');
    }

    this.handleClickOnBeginVerificationButton();
  },

  methods: {
    /**
     * Send SMS to the given number.
     */
    sendSMSVerificationCode () {
      axios.post(this.apiRouteSendSmsVerificationCode, {
        phone_number: this.getFormattedPhoneNumber,
      }).then(({ data }) => {
        this.codeSent = true;
        this.attemptsLeft = data.data.attemptsLeft;
        this.$loader.hide();
      }).catch((e) => {
        VueToast().error(e.response?.data?.error ?? e.message, {
          position: 'top-right',
        });
      });
    },

    /**
     * Enter the code received from SMS and verify it.
     */
    submitVerificationCode () {
      const { num1, num2, num3, num4, num5, num6 } = this.otp;
      const code = `${num1}${num2}${num3}${num4}${num5}${num6}`;

      axios.post(this.apiRouteCheckSmsVerificationCode, {
        code,
      }).then(({ data }) => {
        if (data.error) return;
        window.location = '/';
      }).catch((e) => {
        VueToast().error(e.response?.data?.error ?? e.message, {
          position: 'top-right',
        });
      });
    },

    /**
     * When we copy/paste a code in the first field, we split and fill in the other inputs.
     *
     * @param e
     */
    handleInputPaste (e) {
      // Chrome doesn't get the e.data, it's always empty, fallback to value then.
      const data = e.data || e.target.value;

      // Shouldn't happen, just in case.
      if (!data) return;

      // Here is a normal behavior, not a paste action.
      if (data.length === 1) {
        return;
      }

      const id = e.target.getAttribute('id');
      const index = id.slice(id.length - 1);

      for (let i = 0; i < data.length; i++) {
        this.otp[`num${Number(index) + i}`] = data[i];
      }
    },

    /**
     * When we click on an empty field, if previous field is empty, we focus it.
     *
     * @param e
     */
    handleInputFocus (e) {
      // If the focus element is the first one, do nothing
      const id = e.target.getAttribute('id');
      const index = id.slice(id.length - 1);

      if (Number(index) === 1) {
        return;
      }

      // Chrome doesn't get the e.data, it's always empty, fallback to value then.
      const data = e.data || e.target.value;

      // If value of input 1 is empty, focus it.
      if (data === '') {
        e.target.focus();
      }

      // If value of a previous input is empty, focus it.
      if (e.target.previousElementSibling.value === '') {
        e.target.previousElementSibling.focus();
      }
    },

    /**
     * Register the keyup event on otc inputs.
     */
    registerInputKeyUpEvents () {
      const $form = document.querySelector('#js-otc-form');
      const $inputs = $form.querySelectorAll('input[type="number"]');

      $inputs.forEach(($input) => {
        $input.addEventListener('keyup', this.handleInputKeyUp);
      });
    },

    /**
     * When we fill in a field, we go to the next one.
     *
     * @param e
     */
    handleInputKeyUp (e) {
      // Break if Shift, Tab, CMD, Option, Control.
      if (e.keyCode === 16 || e.keyCode === 9 || e.keyCode === 224 || e.keyCode === 18 || e.keyCode === 17) {
        return;
      }

      // On Backspace or left arrow, go to the previous field.
      if (
        (e.keyCode === 8 || e.keyCode === 37)
        && e.target.previousElementSibling
        && e.target.previousElementSibling.tagName === 'INPUT'
      ) {
        e.target.previousElementSibling.select();
      }

      // Otherwise, on right arrow, go to the next field.
      if (
        e.keyCode !== 8
        && e.target.nextElementSibling
        && e.target.nextElementSibling.tagName === 'INPUT'
      ) {
        e.target.nextElementSibling.select();
      }

      // If the target is populated to quickly, value length can be > 1
      if (e.target.value.length > 1) {
        this.handleInputPaste(e);
      }
    },

    /**
     * While on this page, override the "Begin Verification" button click action.
     * On click, scroll the user down to the content area.
     */
    handleClickOnBeginVerificationButton () {
      const $beginVerificationButton = document.getElementById('js-begin-verification-button');
      $beginVerificationButton.addEventListener('click', this.$scroll.to.bind(null, 'js-breadcrumb'), false);
    },
  },
};
</script>
