<template>
  <btn
    type="button"
    class="btn btn-warning w-100"
    @click="messageSeller"
  >
    <slot />
  </btn>
</template>

<script>

export default {
  props: {
    guest: {
      type: Boolean,
      required: false,
      default: false,
    },
    unverified: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => ({
    modals: {
      guestInfo: null,
      unverifiedInfo: null,
      messageSeller: null,
    },
  }),

  methods: {
    messageSeller () {
      if (this.guest) {
        this.showModal('js-guest-message-seller-modal', 'guestInfo');
        return;
      }

      if (this.unverified) {
        this.showModal('js-unverified-message-seller-modal', 'unverifiedInfo');
        return;
      }

      this.showModal('js-form-message-seller-modal', 'messageSeller');
    },

    showModal (nodeId, modalId) {
      const $modal = document.getElementById(nodeId);

      if (!$modal) {
        this.$debug.error(`[Error] "Element with ID #${nodeId}" cannot be found!`);
        return;
      }

      this.modals[modalId] = window.bootstrap.Modal.getOrCreateInstance($modal);
      this.modals[modalId].show();
    },
  },
};
</script>
