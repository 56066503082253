/* eslint-disable no-console */
export default {
  log (...args) {
    if (window.debugModeEnabled) {
      console.log(...args);
    }
  },

  error (...args) {
    if (window.debugModeEnabled) {
      console.error(...args);
    }
  },
};
