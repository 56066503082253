const _attachParams = (url, params) => {
  let route = url;

  Object.entries(params).forEach((param) => {
    const [key, value] = param;
    route += route.includes('?') ? '&' : '?';
    route += `${key}=${value}`;
  });

  return route;
};

const _redirectTo = (url) => {
  window.location = url;
};

const _reloadPage = () => {
  window.location.reload();
};

export default {
  attachParams: _attachParams,
  redirectTo: _redirectTo,
  reloadPage: _reloadPage,
};
