export default {
  showDangerAlert (text) {
    window.emitter.emit('SHOW_ALERT', ['danger', text]);
  },

  showSuccessAlert (text) {
    window.emitter.emit('SHOW_ALERT', ['success', text]);
  },

  hideAll () {
    window.emitter.emit('HIDE_ALERTS');
  },
};
