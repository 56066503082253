<template>
  <btn
    v-if="!response"
    type="button"
    class="btn btn-primary w-100"
    @click="showPhoneNumber"
  >
    <i class="fad fa-phone-square-alt me-1" /> Show Phone Number
  </btn>
  <a
    v-else
    :href="`tel:${response._formatted_phone_number}`"
    class="btn btn-primary w-100 mb-3"
  >
    <i class="fad fa-phone-square-alt me-1" /> {{ response.phone_code }} {{ response.phone_number }}
  </a>
</template>

<script>
import BaseValidationMixin from '@js/v-mixins/BaseValidationMixin';

export default {
  mixins: [BaseValidationMixin],

  props: {
    guest: {
      type: Boolean,
      required: false,
      default: false,
    },
    adId: {
      type: [Number, String],
      required: true,
    },
    adType: {
      type: String,
      required: true,
    },
    phoneVisibility: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    response: null,
    modals: {
      guestInfo: null,
    },
  }),

  methods: {
    showPhoneNumber () {
      if (this.guest && this.phoneVisibility === 'private') {
        this.showGuestModal();
        return;
      }

      const axiosInstance = axios.create();

      let apiUrl = '';

      if (this.adType === 'sale') {
        apiUrl = `/api/shared/sale-ad-phone-reveals/${this.adId}`;
      } else if (this.adType === 'stud') {
        apiUrl = `/api/shared/stud-ad-phone-reveals/${this.adId}`;
      }

      axiosInstance.get(apiUrl)
        .then(({ data }) => {
          if (data.error) return;
          this.response = data.data;
          this.updatePhoneRevealsCount();
        }).catch((e) => this.displayValidationMessages(e));
    },

    updatePhoneRevealsCount () {
      const $node = document.querySelector('.js-phone-reveals-count');

      if (!$node) return;

      const oldCount = $node.getAttribute('data-count');
      const newCount = this.response._phone_reveals;

      if (Number(oldCount) === Number(newCount)) return;

      $node.innerText = $node.getAttribute('data-increment-count');
    },

    showGuestModal () {
      const nodeId = 'js-guest-reveal-phone-number-modal';
      const $modal = document.getElementById(nodeId);

      if (!$modal) {
        this.$debug.error(`[Error] "Element with ID #${nodeId}" cannot be found!`);
        return;
      }

      this.modals.guestInfo = window.bootstrap.Modal.getOrCreateInstance($modal);
      this.modals.guestInfo.show();
    },
  },
};
</script>
