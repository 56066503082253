const loaderId = 'js-loader';

export default {
  hide () {
    const loader = document.getElementById(loaderId);

    if (loader) loader.style.display = 'none';
  },

  show () {
    const loader = document.getElementById(loaderId);

    if (loader) loader.style.display = '';
  },
};
