<template>
  <button
    type="button"
    class="btn"
    @click="$emit('click')"
  >
    <slot />
  </button>
</template>

<script>
export default {
  emits: ['click'],
};
</script>
