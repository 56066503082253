import * as bootstrap from 'bootstrap';
import axios from 'axios';
import mitt from 'mitt';

window.axios = axios;
window.bootstrap = bootstrap;
window.emitter = mitt();

const axiosDefaults = window.axios.defaults;
const axiosInterceptors = window.axios.interceptors;

/**
 * Configure axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */
axiosDefaults.baseURL = '/';
axiosDefaults.headers.common['X-CSRF-TOKEN'] = document.head.querySelector('meta[name="csrf-token"]').content;
axiosDefaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

const userProxyId = document.querySelector('meta[name="user-proxy-id"]')?.content;
if (userProxyId) axiosDefaults.headers.common['X-USER-PROXY-ID'] = userProxyId;

/**
 * Display the loader when a request is made.
 */
axiosInterceptors.request.use((RequestConfig) => {
  window.app.config.globalProperties.$loader.show();

  return RequestConfig;
});

/**
 * Hide the loader when a response is received.
 */
axiosInterceptors.response.use((Response) => {
  const errorMessage = Response.data.error;
  if (!errorMessage) return Response;

  window.app.config.globalProperties.$alerts.showDangerAlert(errorMessage);
  window.app.config.globalProperties.$loader.hide();

  return Promise.reject(errorMessage);
}, (error) => {
  const errorResponse = error.response;

  if (errorResponse.status !== 422) {
    const errorMessage = errorResponse.data?.message?.trim() || error.message;
    if (errorMessage) window.app.config.globalProperties.$alerts.showDangerAlert(errorMessage);

    window.app.config.globalProperties.$loader.hide();
  }

  return Promise.reject(error);
});
