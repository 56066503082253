export default {
  data: () => ({
    serverErrors: {},
    validationErrorsMediaLibrary: {},
  }),

  methods: {
    displayValidationMessages (error) {
      const serverErrors = error?.response?.data?.errors;
      if (!serverErrors || this.$lodash.isEmpty(serverErrors)) return;

      Object.keys(serverErrors).forEach((errorKey) => {
        serverErrors[errorKey].forEach((errorMessage) => {
          this.serverErrors[errorKey] = [];
          this.serverErrors[errorKey][this.serverErrors[errorKey].length] = {
            $uuid: this.$uuid(),
            $message: errorMessage,
          };
        });

        if (!Object.prototype.hasOwnProperty.call(this.form ?? {}, errorKey)) {
          this.serverErrors[errorKey].forEach((err) => {
            this.$alerts.showDangerAlert(err.$message);
          });
        }
      });

      this.$loader.hide();
      this.$scroll.top();
    },

    getErrors (key) {
      return [...this.v$.form[key]?.$errors ?? [], ...this.serverErrors[key] ?? []];
    },
  },
};
