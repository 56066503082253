export default {
  mounted (el, props) {
    el.setAttribute('title', props.value);
    el.setAttribute('data-bs-toggle', 'tooltip');

    if (!el.getAttribute('data-bs-placement')) {
      el.setAttribute('data-bs-placement', 'top');
    }
  },
};
