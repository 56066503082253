import * as Sentry from '@sentry/vue';
import { createApp } from 'vue';

import '@js/bootstrap';

// Plugins
import PluginAlerts from '@js/v-plugins/Alerts';
import PluginDebug from '@js/v-plugins/Debug';
import PluginLoader from '@js/v-plugins/Loader';
import PluginLodash from '@js/v-plugins/Lodash';
import PluginRoute from '@js/v-plugins/Route';
import PluginRouter from '@js/v-plugins/Router';
import PluginScroll from '@js/v-plugins/Scroll';
import PluginSessionAlerts from '@js/v-plugins/SessionAlerts';
import PluginTooltips from '@js/v-plugins/Tooltips';
import PluginUUID from '@js/v-plugins/UUID';

// Directives
import globalDirectives from '@js/v-directives';

// Components
import Btn from '@js/v-components/Btn';
import DogBreedDescriptionToggler from '@js/v-components/DogBreedDescriptionToggler';
import FavouriteButton from '@js/v-components/FavouriteButton';
import MessageSellerButton from '@js/v-components/MessageSellerButton';
import ShowPhoneNumberButton from '@js/v-components/ShowPhoneNumberButton';
import FormPhoneVerification from '@js/v-components/forms/FormPhoneVerification';
import ManageSaleAd from '@js-advertiser/v-components/ManageSaleAd';
import ManageStudAd from '@js-advertiser/v-components/ManageStudAd';
import ManageVetAd from '@js-advertiser/v-components/ManageVetAd';

const app = createApp({
  mounted () {
    this.showSessionAlerts();
    this.$tooltips.init();
  },

  methods: {
    showSessionAlerts () {
      let alertKeys;

      alertKeys = Object.keys(localStorage);
      alertKeys = alertKeys.filter((key) => key.startsWith('sessionAlert'));

      alertKeys.forEach((key) => {
        this.$alerts.showSuccessAlert(localStorage.getItem(key));
        localStorage.removeItem(key);
      });
    },
  },
});

/* Init Sentry */
Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
  integrations: [
    new Sentry.Replay(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

app.config.globalProperties.$alerts = PluginAlerts;
app.config.globalProperties.$debug = PluginDebug;
app.config.globalProperties.$loader = PluginLoader;
app.config.globalProperties.$lodash = PluginLodash;
app.config.globalProperties.$route = PluginRoute;
app.config.globalProperties.$router = PluginRouter;
app.config.globalProperties.$scroll = PluginScroll;
app.config.globalProperties.$sessionAlerts = PluginSessionAlerts;
app.config.globalProperties.$tooltips = PluginTooltips;
app.config.globalProperties.$uuid = PluginUUID;

app.use(globalDirectives);

app.component('ManageSaleAd', ManageSaleAd);
app.component('ManageStudAd', ManageStudAd);
app.component('ManageVetAd', ManageVetAd);

app.component('Btn', Btn);
app.component('DogBreedDescriptionToggler', DogBreedDescriptionToggler);
app.component('FavouriteButton', FavouriteButton);
app.component('FormPhoneVerification', FormPhoneVerification);
app.component('MessageSellerButton', MessageSellerButton);
app.component('ShowPhoneNumberButton', ShowPhoneNumberButton);

app.mount('#app');

window.app = app;
