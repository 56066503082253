const currentRouteHash = window.location.hash;
const currentRouteHref = window.location.href;
const currentRouteOrigin = window.location.origin;
const currentRouteParams = window.currentRouteParams;
const currentRoutePath = window.location.pathname;
const currentRouteQuery = window.location.search;

const _getCurrentRouteFullPath = () => {
  return currentRouteHref.replace(currentRouteOrigin, '');
};

const _getCurrentRouteHash = () => {
  return currentRouteHash;
};

const _getCurrentRouteParams = () => {
  return JSON.parse(currentRouteParams);
};

const _getCurrentRoutePath = () => {
  return currentRoutePath;
};

const _getCurrentRouteQuery = () => {
  const res = {};

  let query = currentRouteQuery;

  query = query.trim();
  query = query.replace(/^(\?|#|&)/, '');

  // If the query doesn't exist, return an empty object
  if (!query) {
    return res;
  }

  // Otherwise, convert the query string to an object
  query.split('&').forEach((param) => {
    const parts = param.replace(/\+/g, ' ').split('=');
    const key = decodeURIComponent(parts.shift());
    const val = parts.length > 0 ? decodeURIComponent(parts.join('=')) : null;

    if (res[key] === undefined) {
      res[key] = val;
    } else if (Array.isArray(res[key])) {
      res[key].push(val);
    } else {
      res[key] = [res[key], val];
    }
  });

  return res;
};

export default {
  fullPath: _getCurrentRouteFullPath(),
  hash: _getCurrentRouteHash(),
  params: _getCurrentRouteParams(),
  path: _getCurrentRoutePath(),
  query: _getCurrentRouteQuery(),
};
