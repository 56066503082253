<template :key="favourited">
  <btn
    v-if="favourited"
    data-bs-toggle="tooltip"
    data-bs-original-title="Remove from Favourites"
    type="button"
    class="sale-ad-card-favourite-button active"
    @click="removeFromFavourites"
  >
    <i class="fad fa-heart" />
  </btn>
  <btn
    v-else
    data-bs-toggle="tooltip"
    data-bs-original-title="Add to Favourites"
    type="button"
    class="sale-ad-card-favourite-button"
    @click="addToFavourites"
  >
    <i class="fad fa-heart" />
  </btn>
</template>

<script>
import BaseValidationMixin from '@js/v-mixins/BaseValidationMixin';

const API_URL = '/api/shared/favourites';

export default {
  mixins: [BaseValidationMixin],

  props: {
    favouritedId: {
      type: [Number, String],
      required: false,
      default: '',
    },
    modelId: {
      type: [Number, String],
      required: true,
    },
    modelType: {
      type: String,
      required: true,
    },
    guest: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => ({
    id: null,
    favourited: false,
    modals: {
      guestInfo: null,
    },
  }),

  created () {
    this.favourited = Boolean(this.favouritedId);
  },

  methods: {
    addToFavourites () {
      if (this.guest) {
        this.showGuestModal();
        return;
      }

      const axiosInstance = axios.create();

      axiosInstance.post(API_URL, {
        model_id: this.modelId,
        model_type: this.modelType,
      }).then(({ data }) => {
        if (data.error) return;

        this.id = data.data.id;
        this.favourited = true;
        this.$tooltips.destroy();
        this.incrementFavouritesCount();
        this.$nextTick(() => this.$tooltips.init());
      }).catch((e) => this.displayValidationMessages(e));
    },

    removeFromFavourites () {
      const axiosInstance = axios.create();

      axiosInstance.delete(`${API_URL}/${this.id ?? this.favouritedId}`)
        .then(({ data }) => {
          if (data.error) return;

          this.favourited = false;
          this.$tooltips.destroy();
          this.decrementFavouritesCount();
          this.$nextTick(() => this.$tooltips.init());
        }).catch((e) => this.displayValidationMessages(e));
    },

    updateFavouritesCount (type) {
      const $favouritesCount = document.querySelectorAll('.js-favourites-count');
      const incrementFunc = (fullMatch, n) => `(${Number(n) + 1})`;
      const decrementFunc = (fullMatch, n) => `(${Number(n) - 1})`;
      const updateFunc = type === 'increment' ? incrementFunc : decrementFunc;

      $favouritesCount.forEach(($node) => {
        const dataAttribute = type === 'increment' ? 'data-increment-count' : 'data-decrement-count';
        if ($node.hasAttribute(dataAttribute)) {
          if (type === 'increment') {
            $node.setAttribute('data-decrement-count', $node.innerText);
          } else {
            $node.setAttribute('data-increment-count', $node.innerText);
          }
          $node.innerText = $node.getAttribute(dataAttribute);
        } else if ($node.innerHTML.match(/\((\d+)\)/)) {
          $node.innerHTML = $node.innerHTML.replace(/\((\d+)\)/, updateFunc);
        } else {
          $node.innerHTML = updateFunc(null, Number($node.innerHTML)).replace(/[()]/g, '');
        }
      });
    },

    incrementFavouritesCount () {
      this.updateFavouritesCount('increment');
    },

    decrementFavouritesCount () {
      this.updateFavouritesCount('decrement');
    },

    showGuestModal () {
      const nodeId = 'js-guest-add-to-favourites-modal';
      const $modal = document.getElementById(nodeId);

      if (!$modal) {
        this.$debug.error(`[Error] "Element with ID #${nodeId}" cannot be found!`);
        return;
      }

      this.modals.guestInfo = window.bootstrap.Modal.getOrCreateInstance($modal);
      this.modals.guestInfo.show();
    },
  },
};
</script>
